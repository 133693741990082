/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  LinearProgress,
  Box,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  IconButton,
  Tooltip,
  Snackbar,
  Alert,
} from '@mui/material';
import {
  uploadReference,
  fetchReferenceList,
  deleteReference,
  getReference,
  referenceActions,
} from '@ink-ai/portal/reducers/reference';
import { AxiosProgressEvent } from 'axios';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import ReferenceDetailDialog from './ReferenceDetailDialog';
import { RootState } from '@ink-ai/portal/reducers';

export const UploadFile = () => {
  const dispatch = useDispatch<any>();
  const referenceState = useSelector((state: RootState) => state.reference);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [open, setOpen] = useState(false);
  const uploading = useSelector((state: RootState) => state.reference.loading);
  const progress = useSelector((state: RootState) => state.reference.progress);
  const fileList = useSelector((state: RootState) => state.reference.fileList);
  const deleting = useSelector((state: RootState) => state.reference.deleting);
  const selectedFileDetail = useSelector(
    (state: RootState) => state.reference.selectedFileDetail,
  );

  useEffect(() => {
    dispatch(fetchReferenceList());
  }, [dispatch]);

  const MAX_FILE_SIZE_MB = 2;

  const handleFile = (file: File | null) => {
    if (file) {
      if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        dispatch(
          referenceActions.setReferenceErrorMessage(
            `File size exceeds ${MAX_FILE_SIZE_MB}MB limit`,
          ),
        );
      } else if (validateFileType(file)) {
        setSelectedFile(file);
        uploadSelectedFile(file);
      } else {
        dispatch(
          referenceActions.setReferenceErrorMessage(
            'Please select allowed file type',
          ),
        );
      }
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    handleFile(file);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    handleFile(file);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleClick = () => {
    document.getElementById('fileInput')?.click();
  };

  const validateFileType = (file: File) => {
    const validTypes = [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];
    return validTypes.includes(file.type);
  };

  const uploadSelectedFile = (file: File) => {
    const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / (progressEvent.total || 1),
      );
      dispatch(referenceActions.setProgress(percentCompleted));
    };

    dispatch(uploadReference({ file, onUploadProgress })).then(() => {
      setSelectedFile(null);
    });
  };

  const handleToggle = (index: number, event: React.MouseEvent) => {
    // Check if the click is on the ListItem and not on the IconButton
    if ((event.target as HTMLElement).closest('button') === null) {
      dispatch(referenceActions.toggleReferenceCheck(index));
    }
  };

  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleDelete = (uuid: string) => {
    dispatch(deleteReference(uuid));
  };

  const handleView = (uuid: string) => {
    dispatch(getReference(uuid)).then(() => {
      setOpen(true);
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Typography
        gutterBottom
        variant="subtitle1"
        component="div"
        color="primary"
        fontWeight="bold"
      >
        References
      </Typography>
      <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onClick={handleClick}
        style={{
          border: '2px dashed #007bff',
          borderRadius: '5px',
          padding: '20px',
          textAlign: 'center',
          cursor: 'pointer',
        }}
      >
        <Typography>
          Drag and drop or{' '}
          <span style={{ textDecoration: 'underline' }}>browse files</span>
          <br />
          <span style={{ color: '#888888' }}>
            .docx or .xlsx, max {MAX_FILE_SIZE_MB} MB
          </span>
        </Typography>
        <input
          id="fileInput"
          type="file"
          accept=".docx, .xlsx"
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </div>
      <Snackbar
        open={referenceState.referenceErrorMessageOpen}
        autoHideDuration={3000}
        onClose={() => dispatch(referenceActions.closeReferenceErrorMessage())}
      >
        <Alert
          onClose={() =>
            dispatch(referenceActions.closeReferenceErrorMessage())
          }
          severity="error"
          sx={{ width: '100%' }}
        >
          {referenceState.referenceErrorMessage}
        </Alert>
      </Snackbar>
      {uploading && selectedFile && (
        <Typography variant="body1" gutterBottom>
          Uploading file: {selectedFile.name}
        </Typography>
      )}
      {uploading && (
        <Box mt={2}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      )}
      <List>
        {fileList.map((file, index) => (
          <ListItem
            key={file.id}
            dense
            button
            onClick={(event) => handleToggle(index, event)}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box display="flex" alignItems="center">
              <Checkbox
                edge="start"
                checked={file.checked}
                tabIndex={-1}
                disableRipple
              />
              <Tooltip title={file.name} placement="top-start">
                <ListItemText
                  primary={file.name}
                  primaryTypographyProps={{
                    style: {
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '200px', // 控制截断宽度
                    },
                  }}
                />
              </Tooltip>
            </Box>
            {hoveredIndex === index && (
              <Box>
                <IconButton edge="end" onClick={() => handleView(file.id)}>
                  <VisibilityIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  onClick={() => handleDelete(file.id)}
                  disabled={deleting}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            )}
          </ListItem>
        ))}
      </List>
      <ReferenceDetailDialog
        open={open}
        onClose={handleClose}
        referenceDetail={selectedFileDetail}
      />
    </div>
  );
};
