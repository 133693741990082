/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { InputContent } from './InputContent';
import { RoundedButton } from '@ink-ai/portal/common/components/Buttons';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  TextareaAutosize,
  Button,
  Badge,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@ink-ai/portal/reducers';
import { TranslateRequestDtoTargetLanguageEnum } from '@ink-ai/insight-service-sdk';
import {
  COMPOSE_TASKS,
  REWRITE_COMPOSE_TASKS,
  compose,
  startCompose,
} from '@ink-ai/portal/reducers/compose';
import { GeneratedContent } from './GeneratedContent';
import { UploadFile } from './UploadFile';
import { ArticleGenerate } from './ArticleGenerate';
import debounce from 'lodash-es/debounce';
import { GlossaryDialog } from '@ink-ai/portal/insight-hub/components/glossary/GlossaryCollectionsDialog';
import { ReferenceStoreDialog } from '@ink-ai/portal/insight-hub/components/reference-store/ReferenceStoreDialog';

const languages = {
  [TranslateRequestDtoTargetLanguageEnum.EnUs]: 'English',
  [TranslateRequestDtoTargetLanguageEnum.ZhCn]: 'Chinese',
  [TranslateRequestDtoTargetLanguageEnum.Fr]: 'French',
  [TranslateRequestDtoTargetLanguageEnum.Ja]: 'Japanese',
  [TranslateRequestDtoTargetLanguageEnum.Ko]: 'Korean',
};

export interface ComposeProps {
  className?: string;
}

export const Compose = ({ className }: ComposeProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [languageSelectOpen, setLanguageSelectOpen] = useState(false);
  const [sourceLanguageSelectOpen, setSourceLanguageSelectOpen] =
    useState(false);
  const [userPrompt, setUserPrompt] = useState('');
  const [glossaryDialogOpen, setGlossaryDialogOpen] = useState(false);
  const selectedGlossaries = useSelector(
    (state: RootState) => state.compose.selectedGlossaries || [],
  );
  const [referenceStoreDialogOpen, setReferenceStoreDialogOpen] =
    useState(false);
  const selectedReferenceStores = useSelector(
    (state: RootState) => state.compose.selectedReferenceStores || [],
  );

  const scrollToBottom = useMemo(
    () =>
      debounce(() => {
        if (containerRef.current) {
          if (containerRef.current.scrollTo) {
            containerRef.current.scrollTo(0, containerRef.current.scrollHeight);
          } else {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
          }
        }
      }, 10),
    [],
  );

  const handleGlossaryButtonClick = () => {
    setGlossaryDialogOpen(true);
  };

  const handleGlossaryDialogClose = () => {
    setGlossaryDialogOpen(false);
  };

  const handleGlossaryApply = (selectedGlossaryUuids: string[]) => {
    dispatch(compose.actions.setSelectedGlossaries(selectedGlossaryUuids));
    setGlossaryDialogOpen(false);
  };

  const handleReferenceStoreButtonClick = () => {
    setReferenceStoreDialogOpen(true);
  };

  const handleReferenceStoreDialogClose = () => {
    setReferenceStoreDialogOpen(false);
  };

  const handleReferenceStoreApply = (selectedReferenceStoreUuids: string[]) => {
    dispatch(
      compose.actions.setSelectedReferenceStores(selectedReferenceStoreUuids),
    );
    setReferenceStoreDialogOpen(false);
  };

  const composeState = useSelector((state: RootState) => state.compose);
  const selectedFileIds = useSelector(
    (state: RootState) => state.reference.selectedFileIds,
  );
  const showRewriteInputOutput = useMemo(
    () => REWRITE_COMPOSE_TASKS.includes(composeState.task as any),
    [composeState.task],
  );
  const dispatch = useDispatch<any>();
  const isInputTextValid = useMemo(() => {
    if (composeState.task === 'custom') {
      return !!userPrompt; // allow user to compose with empty inputtedText
    }
    if (!composeState.inputtedText || composeState.inputtedText.length === 0) {
      return false;
    }
    if (composeState.inputtedText.length > 10000) {
      return false;
    }
    return true;
  }, [composeState.inputtedText, composeState.task, userPrompt]);

  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom, composeState.generatedText]);

  const handleComposeClick = () => {
    if (composeState.task === 'custom') {
      dispatch(
        startCompose({
          task: 'custom',
          text: composeState.inputtedText,
          userPrompt,
          referenceIdList: selectedFileIds,
        }),
      );
    } else {
      dispatch(startCompose(undefined));
    }
  };

  return (
    <div
      className={`h-screen flex flex-col overflow-y-auto text-left ${className}`}
      ref={containerRef}
    >
      <div className="container mx-auto px-4">
        <div className="py-3">
          {COMPOSE_TASKS.map((type) => (
            <span key={type} className="mr-1.5 mb-1.5 inline-block">
              <RoundedButton
                variant={type === composeState.task ? 'contained' : 'outlined'}
                size="medium"
                onClick={() => dispatch(compose.actions.setTask(type))}
                color="info"
              >
                <Typography textTransform="capitalize">{type}</Typography>
              </RoundedButton>
            </span>
          ))}
        </div>
        {showRewriteInputOutput && <InputContent />}
        {composeState.task === 'custom' && (
          <div className="py-3">
            <TextareaAutosize
              minRows={3}
              maxRows={6}
              placeholder="Enter your custom prompt here"
              value={userPrompt}
              className="w-full border border-solid border-sky-500 rounded-md p-2"
              onChange={(e) => setUserPrompt(e.target.value)}
              autoFocus={true}
            />
          </div>
        )}
        {composeState.task === 'translate' && (
          <div className="py-3">
            <div className="mb-3 flex w-full">
              <Badge
                badgeContent={selectedGlossaries.length}
                color="error"
                className="w-full"
              >
                <Button
                  onClick={handleGlossaryButtonClick}
                  variant="outlined"
                  fullWidth={true}
                  size="large"
                >
                  Glossary
                </Button>
              </Badge>
            </div>
            <div className="mb-3 flex w-full">
              <Badge
                badgeContent={selectedReferenceStores.length}
                color="error"
                className="w-full"
              >
                <Button
                  onClick={handleReferenceStoreButtonClick}
                  variant="outlined"
                  fullWidth={true}
                  size="large"
                >
                  Reference Store
                </Button>
              </Badge>
            </div>
            <div className="flex space-x-4">
              <FormControl fullWidth>
                <InputLabel id="source-language-select-label">
                  Source Language
                </InputLabel>
                <Select
                  labelId="source-language-label"
                  id="source-language-select"
                  value={composeState.sourceLanguage}
                  label="SourceLanguage"
                  open={sourceLanguageSelectOpen}
                  onClick={(e) => {
                    if ((e.target as any).id === 'source-language-select') {
                      setSourceLanguageSelectOpen(true);
                    } else {
                      setSourceLanguageSelectOpen(false);
                    }
                  }}
                >
                  {Object.entries(languages).map(([k, v]) => (
                    <MenuItem
                      key={k}
                      value={k}
                      onClick={() => {
                        dispatch(compose.actions.setSourceLanguage(k as any));
                      }}
                    >
                      {v}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="target-language-select-label">
                  Target Language
                </InputLabel>
                <Select
                  labelId="target-language-label"
                  id="language-select"
                  value={composeState.targetLanguage}
                  label="TargetLanguage"
                  open={languageSelectOpen}
                  onClick={(e) => {
                    if ((e.target as any).id === 'language-select') {
                      setLanguageSelectOpen(true);
                    } else {
                      setLanguageSelectOpen(false);
                    }
                  }}
                >
                  {Object.entries(languages).map(([k, v]) => (
                    <MenuItem
                      key={k}
                      value={k}
                      onClick={() => {
                        dispatch(compose.actions.setTargetLanguage(k as any));
                      }}
                    >
                      {v}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        )}
        {composeState.task === 'generate article' && <ArticleGenerate />}
        {composeState.task !== 'generate article' && (
          <div className="py-3">
            <RoundedButton
              variant="contained"
              fullWidth={true}
              size="large"
              onClick={handleComposeClick}
              disabled={!isInputTextValid || composeState.isGenerating}
            >
              <AutoFixHighOutlinedIcon className="mr-1" />
              Compose
            </RoundedButton>
          </div>
        )}
        {showRewriteInputOutput &&
          (composeState.isGenerating || composeState.generatedText) && (
            <div className="py-3">
              <GeneratedContent />
            </div>
          )}
        {composeState.task === 'custom' && <UploadFile />}
      </div>

      <GlossaryDialog
        open={glossaryDialogOpen}
        onClose={handleGlossaryDialogClose}
        onApply={handleGlossaryApply}
      />

      <ReferenceStoreDialog
        open={referenceStoreDialogOpen}
        onClose={handleReferenceStoreDialogClose}
        onApply={handleReferenceStoreApply}
      />
    </div>
  );
};
